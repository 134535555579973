exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-docs-aws-getting-started-with-judge-eks-mdx": () => import("./../../../src/pages/docs/aws/getting-started-with-judge-eks.mdx" /* webpackChunkName: "component---src-pages-docs-aws-getting-started-with-judge-eks-mdx" */),
  "component---src-pages-docs-aws-values-mdx": () => import("./../../../src/pages/docs/aws/values.mdx" /* webpackChunkName: "component---src-pages-docs-aws-values-mdx" */),
  "component---src-pages-docs-helm-archivista-mdx": () => import("./../../../src/pages/docs/helm/archivista.mdx" /* webpackChunkName: "component---src-pages-docs-helm-archivista-mdx" */),
  "component---src-pages-docs-helm-configuring-judge-helm-mdx": () => import("./../../../src/pages/docs/helm/configuring-judge-helm.mdx" /* webpackChunkName: "component---src-pages-docs-helm-configuring-judge-helm-mdx" */),
  "component---src-pages-docs-helm-getting-started-with-judge-helm-mdx": () => import("./../../../src/pages/docs/helm/getting-started-with-judge-helm.mdx" /* webpackChunkName: "component---src-pages-docs-helm-getting-started-with-judge-helm-mdx" */),
  "component---src-pages-docs-helm-judge-api-mdx": () => import("./../../../src/pages/docs/helm/judge-api.mdx" /* webpackChunkName: "component---src-pages-docs-helm-judge-api-mdx" */),
  "component---src-pages-docs-helm-judge-mdx": () => import("./../../../src/pages/docs/helm/judge.mdx" /* webpackChunkName: "component---src-pages-docs-helm-judge-mdx" */),
  "component---src-pages-docs-helm-judge-web-mdx": () => import("./../../../src/pages/docs/helm/judge-web.mdx" /* webpackChunkName: "component---src-pages-docs-helm-judge-web-mdx" */),
  "component---src-pages-docs-helm-kratos-mdx": () => import("./../../../src/pages/docs/helm/kratos.mdx" /* webpackChunkName: "component---src-pages-docs-helm-kratos-mdx" */),
  "component---src-pages-docs-helm-kratos-selfservice-ui-node-mdx": () => import("./../../../src/pages/docs/helm/kratos-selfservice-ui-node.mdx" /* webpackChunkName: "component---src-pages-docs-helm-kratos-selfservice-ui-node-mdx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/BlogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/PageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */)
}

