/* eslint-disable prefer-rest-params */
import '@testifysec/wings/dist/wings.css';

import { DefaultLayout } from './src/layouts/DefaultLayout';
import React from 'react';

/**
 * Gatsby Browser API
 * Wraps the page element with the Theme, Header, and Footer components
 * Use the Gatsby Browser API to control the rendering of the page client side
 * @param param0
 * @returns
 */
export const wrapPageElement = ({ element, props }: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return <DefaultLayout {...props}>{element}</DefaultLayout>;
};

export const onRouteUpdate = () => {
  if (typeof window !== 'undefined' && !window.reb2b) {
    const reb2b = (window.reb2b = window.reb2b || []);
    if (reb2b.invoked) return;
    reb2b.invoked = true;
    reb2b.methods = ['identify', 'collect'];
    reb2b.factory = function (method: any) {
      return function () {
        const args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        reb2b.push(args);
        return reb2b;
      };
    };
    for (let i = 0; i < reb2b.methods.length; i++) {
      const key = reb2b.methods[i];
      reb2b[key] = reb2b.factory(key);
    }
    reb2b.load = function (key: string) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'reb2b-tracker';
      script.async = true;
      script.src = 'https://s3-us-west-2.amazonaws.com/b2bjsstore/b/' + key + '/reb2b.js.gz';
      const first = document.getElementsByTagName('script')[0];
      first?.parentNode?.insertBefore(script, first);
    };
    reb2b.SNIPPET_VERSION = '1.0.1';
    reb2b.load('4N210HEPXX6Z');
  }
};
